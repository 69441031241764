import cuid from "cuid";

import { planApiUrl, planApiUrlV2 } from "../configs";

export function getQueryParams() {
  if (window) {
    let params = window.location.search;
    params = params.replace("?", "");

    let temp = params.split("&");

    if (temp[0].split("=")[1] && temp[1].split("=")[1]) {
      return {
        userid: temp[0].split("=")[1],
        language: temp[1].split("=")[1]
      };
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function getPlanLinkFromServer(customer_email, planToken) {
  const data = {
    customer_email: customer_email,
    planToken: planToken
  };

  const response = await fetch(planApiUrl, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });

  return await response.json();
}

export async function getHmacFromServer(language, amount) {
  let nonce = cuid();
  let order_reference = cuid();

  //get current customer url domain
  let customerUrl = window.location.protocol + "//" + window.location.hostname;

  const data = {
    amount: amount,
    nonce: nonce,
    order_reference: order_reference,
    locale: language,
    customer_url: customerUrl
  };

  const response = await fetch(planApiUrlV2, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  }).then(res => res.json());

  return response.data;
}
