import React from "react";
import tickIcon from "../assets/images/tick-icon.svg";

export default function PackageListItem({ content }) {
  return (
    <li className="slider__package-list-item no-border">
      <img
        src={tickIcon}
        alt="icon"
        className="icon  slider__package-list-icon"
      />
      <div className="slider__package-list-content">{content}</div>
    </li>
  );
}
