import React from "react";
import { useTranslation } from "react-i18next";

const SubscriptionFormSuccess = props => {
  const { t } = useTranslation();

  return (
    <div className="sub-form-container checkout__step checkout__step--background checkout__step--email">
      <svg
        onClick={e => {
          e.preventDefault();
          props.closeSuccessDiaglog();
        }}
        className="cross-icon-img"
        id="Component_2_1"
        data-name="Component 2 – 1"
        xmlns="http://www.w3.org/2000/svg"
        width="15.557"
        height="15.557"
        viewBox="0 0 15.557 15.557"
      >
        <rect
          id="Rectangle_276"
          data-name="Rectangle 276"
          width="20"
          height="2"
          rx="1"
          transform="translate(0 14.143) rotate(-45)"
        />
        <rect
          id="Rectangle_301"
          data-name="Rectangle 301"
          width="20"
          height="2"
          rx="1"
          transform="translate(1.415 0) rotate(45)"
        />
      </svg>
      <h2 className="checkout__step-title">
        {t("subscriptionFormSuccess.heading")}
      </h2>
      <svg
        className="success-tick"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
      >
        <g
          id="Group_869"
          data-name="Group 869"
          transform="translate(-130 -331)"
        >
          <circle
            id="Ellipse_141"
            data-name="Ellipse 141"
            cx="16"
            cy="16"
            r="16"
            transform="translate(130 331)"
            fill="#00b947"
          />
          <path
            id="Path_173"
            data-name="Path 173"
            d="M347.152,701.739l4.178,4.178L359.248,698"
            transform="translate(-207.221 -354.717)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="3"
          />
        </g>
      </svg>

      <h3 className="checkout__step-title subtitle mb-5">
        {t("subscriptionFormSuccess.subheading")}
      </h3>
      <div className="success-description">
        <p className="text--large">
          {t("subscriptionFormSuccess.description")}
        </p>
      </div>
    </div>
  );
};

export default SubscriptionFormSuccess;
