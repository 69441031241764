import React from "react";
import { withTranslation } from "react-i18next";
import Scrollchor from "react-scrollchor";
import { plans } from "../configs";

import FeatureBadge from "./featuredBadge";

import tickIcon from "../assets/images/tick-icon.svg";
import SubscriptionForm from "./subscriptionForm";
import SubscriptionFormSuccess from "./subscriptionFormSuccess";

import plan1Img from "../assets/images/kinopass-premium.jpg";
import plan2Img from "../assets/images/kinopass-fan.jpg";
import plan3Img from "../assets/images/kinopass-paev.jpg";
import PackageListItem from "./PackageListItem";

class PlansList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlan: null,
      displaySubForm: false,
      showSuccessDiaglog: false
    };
  }

  onPlanSelect = (plan, displayPrice, name) => {
    this.setState({
      displaySubForm: true,
      selectedPlan: {
        ...plan,
        displayPrice,
        planName: name
      }
    });
  };

  toggleSubForm = () => {
    this.setState({
      displaySubForm: !this.state.displaySubForm,
      selectedPlan: null
    });
  };

  toggleSuccessDiaglog = () => {
    this.setState({
      showSuccessDiaglog: !this.state.showSuccessDiaglog,
      displaySubForm: false,
      selectedPlan: null
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div className="h-container plan-section-container">
        <div className="hero ">
          <div className="h-container">
            <div className="hero__head">
              <h2 className="hero__title">{t("plansSection.heading")}</h2>
            </div>
          </div>
        </div>
        <div className="slider slider--giftcards">
          <div
            className="slider__content js-slider is-first"
            data-slidestoshow="3"
          >
            <div
              className={
                this.state.selectedPlan && this.state.selectedPlan.id === 1
                  ? "slider__content-item slider__content-item--large plan-outter-container pt-20 is-current"
                  : "slider__content-item slider__content-item--large plan-outter-container pt-20"
              }
            >
              <div
                className={
                  this.state.selectedPlan && this.state.selectedPlan.id === 1
                    ? "gift-ticket active"
                    : "gift-ticket"
                }
              >
                <div className="gift-ticket__inner">
                  <div className="image  gift-ticket__image">
                    <img
                      src={plan1Img}
                      alt="plan-img"
                      className="image__img lazyload"
                    />
                  </div>
                  <div className="gift-ticket__content text text--center">
                    <h3 className="gift-ticket__title h2">
                      {t("plansSection.plan1.planName")}
                    </h3>
                    <div className="gift-ticket__description text text--large plans-list-container">
                      <ul className="slider__package-list">
                        <PackageListItem
                          content={t("plansSection.plan1.point1")}
                        />
                        <PackageListItem
                          content={t("plansSection.plan1.point2")}
                        />
                        <PackageListItem
                          content={t("plansSection.plan1.point3")}
                        />
                        {/* <PackageListItem
                          content={t("plansSection.plan1.point4")}
                        /> */}
                        <PackageListItem
                          content={t("plansSection.plan1.point5")}
                        />
                        <PackageListItem
                          content={t("plansSection.plan1.point6")}
                        />
                      </ul>
                    </div>
                    <div
                      className="gift-ticket__disclaimer"
                      dangerouslySetInnerHTML={{
                        __html: t("plansSection.plan1.description")
                      }}
                    ></div>
                    <div className="gift-ticket__price h3 text-color--orange">
                      {t("plansSection.plan1.displayPrice")}
                    </div>
                    <Scrollchor
                      to="#subscribe"
                      animate={{ offset: -30, duration: 600 }}
                    >
                      <button
                        type="button"
                        className="button button--block button--small gift-ticket__add"
                        onClick={e => {
                          e.preventDefault();
                          this.onPlanSelect(
                            plans[0],
                            t("plansSection.plan1.displayPrice"),
                            t("plansSection.plan1.planName")
                          );
                        }}
                      >
                        <div className="button__wrapper">
                          <span className="button__wrapper-inner">
                            {t("plansSection.buttonText")}
                          </span>
                        </div>
                      </button>
                    </Scrollchor>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={
                this.state.selectedPlan && this.state.selectedPlan.id === 2
                  ? "slider__content-item slider__content-item--large plan-outter-container pt-20 is-current"
                  : "slider__content-item slider__content-item--large plan-outter-container pt-20"
              }
            >
              <div
                className={
                  this.state.selectedPlan && this.state.selectedPlan.id === 2
                    ? "gift-ticket active"
                    : "gift-ticket"
                }
              >
                <div className="gift-ticket__inner">
                  <div className="badge-container">
                    <FeatureBadge text="Soovitame" />
                  </div>
                  <div className="image  gift-ticket__image">
                    <img
                      src={plan2Img}
                      alt="plan-img"
                      className="image__img lazyload"
                    />
                  </div>
                  <div className="gift-ticket__content text text--center">
                    <h3 className="gift-ticket__title h2">
                      {t("plansSection.plan2.planName")}
                    </h3>
                    <div className="gift-ticket__description text text--large plans-list-container">
                      <ul className="slider__package-list">
                        <PackageListItem
                          content={t("plansSection.plan2.point1")}
                        />
                        <PackageListItem
                          content={t("plansSection.plan2.point2")}
                        />
                        <PackageListItem
                          content={t("plansSection.plan2.point3")}
                        />
                        {/* <PackageListItem
                          content={t("plansSection.plan2.point4")}
                        /> */}
                        <PackageListItem
                          content={t("plansSection.plan2.point5")}
                        />
                      </ul>
                    </div>
                    <div className="gift-ticket__disclaimer">
                      {t("plansSection.plan2.description")}
                    </div>
                    <div className="gift-ticket__price h3 text-color--orange">
                      {t("plansSection.plan2.displayPrice")}
                    </div>
                    <Scrollchor
                      to="#subscribe"
                      animate={{ offset: -30, duration: 600 }}
                    >
                      <button
                        type="button"
                        className="button button--block button--small gift-ticket__add"
                        onClick={e => {
                          e.preventDefault();
                          this.onPlanSelect(
                            plans[1],
                            t("plansSection.plan2.displayPrice"),
                            t("plansSection.plan2.planName")
                          );
                        }}
                      >
                        <div className="button__wrapper">
                          <span className="button__wrapper-inner">
                            {t("plansSection.buttonText")}
                          </span>
                        </div>
                      </button>
                    </Scrollchor>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={
                this.state.selectedPlan && this.state.selectedPlan.id === 3
                  ? "slider__content-item slider__content-item--large plan-outter-container pt-20 is-current"
                  : "slider__content-item slider__content-item--large plan-outter-container pt-20"
              }
            >
              <div
                className={
                  this.state.selectedPlan && this.state.selectedPlan.id === 3
                    ? "gift-ticket active"
                    : "gift-ticket"
                }
              >
                <div className="gift-ticket__inner">
                  <div className="image  gift-ticket__image">
                    <img
                      src={plan3Img}
                      alt="plan-img"
                      className="image__img lazyload"
                    />
                  </div>
                  <div className="gift-ticket__content text text--center">
                    <h3 className="gift-ticket__title h2">
                      {t("plansSection.plan3.planName")}
                    </h3>
                    <div className="gift-ticket__description text text--large plans-list-container">
                      <ul className="slider__package-list">
                        <li className="slider__package-list-item no-border">
                          <img
                            src={tickIcon}
                            alt="icon"
                            className="icon  slider__package-list-icon"
                          />
                          <div className="slider__package-list-content">
                            {t("plansSection.plan3.point1")}
                          </div>
                        </li>
                        <li className="slider__package-list-item no-border">
                          <img
                            src={tickIcon}
                            alt="icon"
                            className="icon  slider__package-list-icon"
                          />
                          <div className="slider__package-list-content">
                            {t("plansSection.plan3.point2")}
                          </div>
                        </li>
                        <li className="slider__package-list-item no-border">
                          <img
                            src={tickIcon}
                            alt="icon"
                            className="icon  slider__package-list-icon"
                          />
                          <div className="slider__package-list-content">
                            {t("plansSection.plan3.point3")}
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="gift-ticket__disclaimer">
                      {t("plansSection.plan3.description")}
                    </div>
                    <div className="gift-ticket__price h3 text-color--orange">
                      {t("plansSection.plan3.displayPrice")}
                    </div>
                    <Scrollchor
                      to="#subscribe"
                      animate={{ offset: -30, duration: 600 }}
                    >
                      <button
                        type="button"
                        className="button button--block button--small gift-ticket__add"
                        onClick={e => {
                          e.preventDefault();
                          this.onPlanSelect(
                            plans[2],
                            t("plansSection.plan3.displayPrice"),
                            t("plansSection.plan3.planName")
                          );
                        }}
                      >
                        <div className="button__wrapper">
                          <span className="button__wrapper-inner">
                            {t("plansSection.buttonText")}
                          </span>
                        </div>
                      </button>
                    </Scrollchor>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="subscribe">
          {this.state.displaySubForm && (
            <SubscriptionForm
              userInfo={this.props.userInfo}
              planDetail={this.state.selectedPlan}
              showSuccessDiaglog={this.toggleSuccessDiaglog}
              closeSubForm={this.toggleSubForm}
              currLanguage={this.props.currLanguage}
            />
          )}
          {this.state.showSuccessDiaglog && (
            <SubscriptionFormSuccess
              closeSuccessDiaglog={this.toggleSuccessDiaglog}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(PlansList);
