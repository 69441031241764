import React from "react";
import { useTranslation } from "react-i18next";

import logoImg from "../assets/images/logo.svg";
import facebookIcon from "../assets/images/facebook.svg";
import instaIcon from "../assets/images/instagram.svg";
import googlePlayIcon from "../assets/images/google-play.svg";
import appleStoreIcon from "../assets/images/apple-store.svg";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="h-container">
        <div className="logo  footer__logo">
          <a href="/" target="_blank" className="logo__link">
            <img src={logoImg} alt="site-logo" className="logo__img" />
          </a>
        </div>
        <ul className="footer__grid">
          <li className="footer__grid-item ">
            <div className="footer__subitem-wrapper ">
              <h3 className="footer__subtitle">{t("footer.part1.heading")}</h3>
              <div className="footer__text text">
                {t("footer.part1.description")}
              </div>
            </div>
            <div className="footer__subitem-wrapper footer__subitem-wrapper--icon">
              <a
                href="https://www.apollokino.ee/Mobile-App-Android"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="image  footer__icon">
                  <img
                    src={googlePlayIcon}
                    alt="play-store"
                    className="image__img"
                  />
                </div>
              </a>
            </div>
            <div className="footer__subitem-wrapper footer__subitem-wrapper--icon">
              <a
                href="https://www.apollokino.ee/Mobile-App-iOS"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="image  footer__icon">
                  <img
                    src={appleStoreIcon}
                    alt="apple-store"
                    className="image__img"
                  />
                </div>
              </a>
            </div>
          </li>
          <li className="footer__grid-item ">
            <div className="footer__subitem-wrapper ">
              <h3 className="footer__subtitle">{t("footer.part2.heading")}</h3>
              <ul className="footer__subitem-list">
                <li className="footer__subitem-item">
                  <a
                    href={t("footer.part2.link1Link")}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer__subitem-link"
                  >
                    {t("footer.part2.link1")}
                  </a>
                </li>
                {/* <li className="footer__subitem-item">
                  <a href="#" className="footer__subitem-link">
                    {t("footer.part2.link2")}
                  </a>
                </li> */}
                <li className="footer__subitem-item">
                  <a
                    href="https://www.apollokino.ee/job"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer__subitem-link"
                  >
                    {t("footer.part2.link3")}
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer__subitem-wrapper footer__social-item--tablet">
              <h3 className="footer__subtitle">{t("footer.part3.heading")}</h3>
              <ul className="footer__subitem-list">
                <li className="footer__subitem-item footer__subitem-item--w-icon">
                  <a
                    href="https://www.instagram.com/apollokino/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer__subitem-link"
                  >
                    <img
                      src={instaIcon}
                      alt="instagram-icon"
                      className="icon  footer__subitem-icon"
                    />
                    {t("footer.part3.instagram")}
                  </a>
                </li>
                <li className="footer__subitem-item footer__subitem-item--w-icon">
                  <a
                    href="https://facebook.com/ApolloKinoEesti/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer__subitem-link"
                  >
                    <img
                      src={facebookIcon}
                      alt="facebook-icon"
                      className="icon  footer__subitem-icon"
                    />
                    {t("footer.part3.facebook")}
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className="footer__grid-item h-hidden-xs h-hidden-sm">
            <div className="footer__subitem-wrapper h-visible h-hidden-xs">
              <h3 className="footer__subtitle">{t("footer.part3.heading")}</h3>
              <ul className="footer__subitem-list">
                <li className="footer__subitem-item footer__subitem-item--w-icon">
                  <a
                    href="https://www.instagram.com/apollokino/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer__subitem-link"
                  >
                    <img
                      src={instaIcon}
                      alt="instagram-icon"
                      className="icon  footer__subitem-icon"
                    />
                    {t("footer.part3.instagram")}
                  </a>
                </li>
                <li className="footer__subitem-item footer__subitem-item--w-icon">
                  <a
                    href="https://facebook.com/ApolloKinoEesti/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer__subitem-link"
                  >
                    <img
                      src={facebookIcon}
                      alt="facebook-icon"
                      className="icon  footer__subitem-icon"
                    />
                    {t("footer.part3.facebook")}
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className="footer__grid-item ">
            <div className="footer__subitem-wrapper ">
              <h3 className="footer__subtitle">{t("footer.part4.heading")}</h3>
              <div className="footer__text text">
                <p>
                  <a
                    href={t("footer.part4.descriptionLink")}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer__subitem-link"
                  >
                    {t("footer.part4.description")}
                  </a>
                </p>
                <p>
                  <a
                    href="tel:+3726336020"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer__subitem-link"
                  >
                    {t("footer.part4.info")}
                  </a>
                  <br />
                  <a
                    class="footer__subitem-link"
                    href="mailto:info@apollokino.ee"
                  >
                    <b>{t("footer.part4.email1")}</b>
                  </a>
                </p>
                <p>
                  {t("footer.part4.timing")}
                  <br />
                  {t("footer.part4.address")}
                </p>
              </div>
            </div>
          </li>
          <li className="footer__grid-item ">
            <div className="footer__subitem-wrapper ">
              <h3 className="footer__subtitle">{t("footer.part5.heading")}</h3>
              <ul className="footer__subitem-list">
                <li className="footer__subitem-item">
                  <a
                    href="https://www.apollokino.ee/cinemas/apollo-kino-solaris"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer__subitem-link"
                  >
                    {t("footer.part5.link1")}
                  </a>
                </li>
                <li className="footer__subitem-item">
                  <a
                    href="https://www.apollokino.ee/cinemas/akm-info"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer__subitem-link"
                  >
                    {t("footer.part5.link2")}
                  </a>
                </li>
                <li className="footer__subitem-item">
                  <a
                    href="https://www.apollokino.ee/cinemas/ako-info"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer__subitem-link"
                  >
                    {t("footer.part5.link3")}
                  </a>
                </li>
                <li className="footer__subitem-item">
                  <a
                    href="https://www.apollokino.ee/cinemas/ulemiste/aku-info"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer__subitem-link"
                  >
                    {t("footer.part5.link4")}
                  </a>
                </li>
                <li className="footer__subitem-item">
                  <a
                    href="https://www.apollokino.ee/cinemas/akl-info"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer__subitem-link"
                  >
                    {t("footer.part5.link5")}
                  </a>
                </li>
                <li className="footer__subitem-item">
                  <a
                    href="https://www.apollokino.ee/cinemas/ake-info"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer__subitem-link"
                  >
                    {t("footer.part5.link6")}
                  </a>
                </li>
                <li className="footer__subitem-item">
                  <a
                    href="https://www.apollokino.ee/cinemas/akp-info"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer__subitem-link"
                  >
                    {t("footer.part5.link7")}
                  </a>
                </li>
                <li className="footer__subitem-item">
                  <a
                    href="https://www.apollokino.ee/cinemas/aka-info"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer__subitem-link"
                  >
                    {t("footer.part5.link8")}
                  </a>
                </li>
                <li className="footer__subitem-item">
                  <a
                    href="https://www.apollokino.ee/cinemas/akk-info"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer__subitem-link"
                  >
                    {t("footer.part5.link9")}
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
