export const tokenApiUrl = "/api/getJwt";
export const planApiUrl = "/api/getPlanLink";
export const planApiUrlV2 = "/api/getPlanLinkV2";

export const redirectUrl = "https://www.apollokino.ee/projectvegas/";
export const token = "bc49d59cb1412e6cf113a008d1d0d346";
export const phoneNoRegex = /^5[0-9]{6,7}$/;

export const plans = [
  {
    id: 1,
    name: "apollo_premium_pass",
    price: 1999,
    planToken: "v9diuq"
    // planToken: "xthhcs"
  },
  {
    id: 2,
    name: "apollo_fan_pass",
    price: 1299,
    planToken: "86vbhk"
    // planToken: "ccjnvr"
  },
  {
    id: 3,
    name: "apollo_friend_pass",
    price: 999,
    planToken: "agz9h3"
    // planToken: "zxyb2s"
  }
];
