import React from "react";
import { useTranslation } from "react-i18next";

import heroDesktopImg from "../assets/images/kinopass-header-desktop@2x.jpg";
import heroMobileImg from "../assets/images/kinopass-header-mobile.jpg";

const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <div className="hero hero--background-image hero--tall">
      <div className="image  hero__bg-image">
        <img
          src={heroDesktopImg}
          alt="hero"
          className="image__img display-desktop"
        />
        <img
          src={heroMobileImg}
          alt="hero"
          className="image__img display-mobile"
        />
      </div>
      <div className="h-container">
        <div className="hero__head">
          <h1 className="hero__title">{t("heroSection.heading")}</h1>
          <div className="hero__subtitle h3 text--regular">
            <p>{t("heroSection.dp1")}</p>
            <p>
              <strong>{t("heroSection.dp2b")}</strong>
            </p>
            <p>{t("heroSection.dp3")}</p>
            <p>{t("heroSection.dp4")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
