import React from "react";
import { useTranslation } from "react-i18next";

const KKKSection = () => {
  const { t } = useTranslation();

  return (
    <div className="h-container kkk-section-container">
      <div className="hero ">
        <div className="h-container">
          <div className="hero__head">
            <h2 className="hero__title">{t("kkkSection.heading")}</h2>
          </div>
        </div>
      </div>
      <div className="grid grid--huge-gap">
        <div className="grid__col grid__col--sm-8 text text--large">
          <ul>
            <li>
              <p className="make-semi-bold">{t("kkkSection.q21")}</p>
              <p dangerouslySetInnerHTML={{ __html: t("kkkSection.a21") }} />
            </li>
            <li>
              <p className="make-semi-bold">{t("kkkSection.q0")}</p>
              <p dangerouslySetInnerHTML={{ __html: t("kkkSection.a0") }} />
            </li>
            <li>
              <p className="make-semi-bold">{t("kkkSection.q1")}</p>
              <p>{t("kkkSection.a1")}</p>
            </li>
            <li>
              <p className="make-semi-bold">{t("kkkSection.q2")}</p>
              <p>{t("kkkSection.a2")}</p>
            </li>
            <li>
              <p className="make-semi-bold">{t("kkkSection.q3")}</p>
              <p>{t("kkkSection.a3")}</p>
            </li>
            <li>
              <p className="make-semi-bold">{t("kkkSection.q4")}</p>
              <p>{t("kkkSection.a4")}</p>
            </li>
            <li>
              <p className="make-semi-bold">{t("kkkSection.q5")}</p>
              <p>{t("kkkSection.a5")}</p>
            </li>
            <li>
              <p className="make-semi-bold">{t("kkkSection.q6")}</p>
              <p>{t("kkkSection.a6")}</p>
            </li>
            <li>
              <p className="make-semi-bold">{t("kkkSection.q7")}</p>
              <p>{t("kkkSection.a7")}</p>
            </li>
            <li>
              <p className="make-semi-bold">{t("kkkSection.q8")}</p>
              <p>{t("kkkSection.a8")}</p>
            </li>
            <li>
              <p className="make-semi-bold">{t("kkkSection.q9")}</p>
              <p>{t("kkkSection.a9")}</p>
            </li>
            <li>
              <p className="make-semi-bold">{t("kkkSection.q10")}</p>
              <p>{t("kkkSection.a10")}</p>
            </li>
          </ul>
        </div>
        <div className="grid__col grid__col--sm-8 text text--large">
          <ul>
            <li>
              <p className="make-semi-bold">{t("kkkSection.q11")}</p>
              <p>{t("kkkSection.a11")}</p>
            </li>
            <li>
              <p className="make-semi-bold">{t("kkkSection.q12")}</p>
              <p>{t("kkkSection.a12")}</p>
            </li>
            <li>
              <p className="make-semi-bold">{t("kkkSection.q13")}</p>
              <p>{t("kkkSection.a13")}</p>
            </li>
            <li>
              <p className="make-semi-bold">{t("kkkSection.q14")}</p>
              <p>{t("kkkSection.a14")}</p>
            </li>
            <li>
              <p className="make-semi-bold">{t("kkkSection.q15")}</p>
              <p>{t("kkkSection.a15")}</p>
            </li>
            <li>
              <p className="make-semi-bold">{t("kkkSection.q16")}</p>
              <p>{t("kkkSection.a16")}</p>
            </li>
            <li>
              <p className="make-semi-bold">{t("kkkSection.q17")}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("kkkSection.a17", {
                    interpolation: { escapeValue: false }
                  })
                }}
              ></p>
            </li>
            <li>
              <p className="make-semi-bold">{t("kkkSection.q18")}</p>
              <p>{t("kkkSection.a18")}</p>
            </li>
            <li>
              <p className="make-semi-bold">{t("kkkSection.q19")}</p>
              <p>{t("kkkSection.a19")}</p>
            </li>
            <li>
              <p className="make-semi-bold">{t("kkkSection.q20")}</p>
              <p>{t("kkkSection.a20")}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default KKKSection;
