import React from "react";

import starIcon from "../assets/images/star-icon.svg";

const FeaturedBadge = props => (
  <div className="tag text--small  schedule__tag featured-tag">
    <img src={starIcon} alt="star" className="icon  tag__icon" />
    {props.text}
  </div>
);

export default FeaturedBadge;
