import React from "react";
import { withTranslation } from "react-i18next";

import { phoneNoRegex, redirectUrl } from "../configs";

import {
  getTokenFromServer,
  sendPinCodeSMS,
  verifyPinCode
} from "../utils/zlick";

import { getPlanLinkFromServer } from "../utils/common";

class SubscriptionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNo: "",
      verificationCode: "",
      aggrementCheckbox: false,
      step: 1,
      error: null,
      userInfo: this.props.userInfo,
      jwtToken: null,
      challengeId: "",
      processing: false,
      planLink: ""
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  async componentDidMount() {
    let planLink = "";
    try {
      const res = await getPlanLinkFromServer(
        this.props.userInfo.userid,
        this.props.planDetail.planToken
      );
      planLink = res.link;
    } catch (error) {
      console.error("Could not fetch everypay link");
    }

    if (planLink) {
      this.setState({
        planLink
      });
    }
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
      error: null
    });
  };

  validatePhoneNo = () => {
    const phoneNo = this.state.phoneNo;

    if (!phoneNo || !phoneNo.trim()) {
      this.setState({
        error: "errorMessages.phoneNoNotEntered"
      });
      return false;
    }

    let reg = phoneNoRegex;
    if (reg.test(phoneNo)) {
      this.setState({
        error: null
      });
      return true;
    } else {
      this.setState({
        error: "errorMessages.phoneNo"
      });
      return false;
    }
  };

  async handleFormSubmit(e) {
    e.preventDefault();

    const handleSuccess = () => {
      this.setState({
        phoneNo: "",
        verificationCode: "",
        aggrementCheckbox: false,
        step: 1,
        error: null,
        jwtToken: null,
        challengeId: ""
      });
      this.props.showSuccessDiaglog();

      //redirect the user after 3 secs
      setTimeout(function() {
        window.location.assign(redirectUrl);
      }, 20 * 1000);
    };

    if (this.state.step === 1) {
      if (this.validatePhoneNo()) {
        this.setState({
          processing: true
        });

        // generate jwt token
        let jwtToken = await getTokenFromServer(
          this.props.planDetail.name,
          this.state.userInfo.userid
        );

        //send pin to no
        const res = await sendPinCodeSMS(jwtToken.token, this.state.phoneNo);

        if (res.subscribed) {
          return handleSuccess();
        }

        //if error comes
        if (res.errorCode) {
          this.setState({
            error: "errorMessages." + res.errorCode.toString()
          });
        } else if (res.challengeId) {
          //if we receicve the changellende id from zclick
          this.setState({
            step: 2,
            error: null,
            jwtToken: jwtToken.token,
            challengeId: res.challengeId
          });
        } else if (res === false) {
          //any other error occurred
          this.setState({
            error: "errorMessages.500"
          });
        }

        this.setState({
          processing: false
        });
      }
    } else if (this.state.step === 2) {
      if (this.state.verificationCode.length === 0) {
        this.setState({
          error: "errorMessages.pinCode"
        });
      } else {
        this.setState({
          processing: true
        });

        //verify pin and subscribe the user if its correct
        const resp = await verifyPinCode(
          this.state.jwtToken,
          this.state.challengeId,
          this.state.verificationCode
        );

        if (resp.errorCode) {
          if (resp.errorCode === 422) {
            this.setState({
              error: "errorMessages." + resp.zlickErrorCode
            });
          } else {
            this.setState({
              error: "errorMessages." + resp.errorCode.toString()
            });
          }
        } else {
          handleSuccess();
        }

        this.setState({
          processing: false
        });
      }
    }
  }

  closeSubsForm = e => {
    e.preventDefault();

    this.setState({
      phoneNo: "",
      verificationCode: "",
      aggrementCheckbox: false,
      step: 1,
      error: null,
      jwtToken: null,
      challengeId: "",
      processing: false
    });

    this.props.closeSubForm();
  };

  toggleModal = () => {
    this.setState({
      isShowingModal: !this.state.isShowingModal
    });
  };

  onEverypayLinkClick = () => {
    window.open(this.state.planLink, "_blank", "noopener noreferrer");
    setTimeout(() => {
      window.location.replace(redirectUrl);
    }, 1500);
  };

  render() {
    const { t } = this.props;
    const closingSvg = (
      <svg
        className="cross-icon-img"
        id="Component_2_1"
        data-name="Component 2 – 1"
        xmlns="http://www.w3.org/2000/svg"
        width="15.557"
        height="15.557"
        viewBox="0 0 15.557 15.557"
      >
        <rect
          id="Rectangle_276"
          data-name="Rectangle 276"
          width="20"
          height="2"
          rx="1"
          transform="translate(0 14.143) rotate(-45)"
        />
        <rect
          id="Rectangle_301"
          data-name="Rectangle 301"
          width="20"
          height="2"
          rx="1"
          transform="translate(1.415 0) rotate(45)"
        />
      </svg>
    );
    const isEveryPayButtonEnabled =
      this.state.aggrementCheckbox && !!this.state.planLink;
    return (
      <div className="sub-form-container checkout__step checkout__step--background checkout__step--email">
        <div onClick={this.closeSubsForm}>{closingSvg}</div>
        <h2 className="checkout__step-title">
          {t("subscriptionForm.heading")}
        </h2>
        <h3 className="checkout__step-title subtitle">
          {this.props.planDetail.planName +
            " - " +
            this.props.planDetail.displayPrice}
        </h3>
        <div className="grid">
          <div className="grid__col grid__col--8-sm">
            <div className="textfield textfield--center checkout__email-field">
              {this.state.step === 1 && (
                <div className="textfield__inner">
                  <div className="textfield__input-wrapper">
                    <label className="textfield__label " htmlFor="phoneNo">
                      {t("subscriptionForm.phoneNoInputText")}
                    </label>
                    {this.state.error !== null && (
                      <p className="error">{t(this.state.error)}</p>
                    )}
                    <input
                      className="textfield__input"
                      type="text"
                      id="phoneNo"
                      name="phoneNo"
                      value={this.state.phoneNo}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
              )}
              {this.state.step === 2 && (
                <div className="textfield__inner">
                  <div className="textfield__input-wrapper">
                    <label
                      className="textfield__label "
                      htmlFor="verificationCode"
                    >
                      {t("subscriptionForm.verificationCodeText")}
                    </label>
                    {this.state.error !== null && (
                      <p className="error">{t(this.state.error)}</p>
                    )}
                    <input
                      className="textfield__input"
                      type="text"
                      id="verificationCode"
                      name="verificationCode"
                      value={this.state.verificationCode}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
              )}
              <div className="check check--large mt-10">
                <input
                  type="checkbox"
                  id="aggrementCheckbox"
                  name="aggrementCheckbox"
                  className="check__input"
                  value={this.state.aggrementCheckbox}
                  checked={this.state.aggrementCheckbox}
                  onChange={this.handleInputChange}
                />
                <label htmlFor="aggrementCheckbox" className="check__label">
                  <span className="check__indicator"></span>
                  <span
                    className="check__text text--large"
                    dangerouslySetInnerHTML={{
                      __html: t("subscriptionForm.checkboxText", {
                        interpolation: { escapeValue: false }
                      })
                    }}
                  />
                </label>
              </div>
              <button
                disabled={
                  this.state.aggrementCheckbox && !this.state.processing
                    ? false
                    : true
                }
                type="button"
                className="button button--block button--small gift-ticket__add form-submit-btn tooltip-parent"
                onClick={this.handleFormSubmit}
              >
                <div className="button__wrapper">
                  <span className="button__wrapper-inner">
                    {this.state.step === 2
                      ? t("subscriptionForm.buttonTextPinCode")
                      : t("subscriptionForm.buttonText")}
                  </span>
                </div>
                {!this.state.aggrementCheckbox && (
                  <span className="tooltiptext">
                    {t("subscriptionBtnTooltip")}
                  </span>
                )}
              </button>
              <br />
              <div className="btm-text-container text--center">
                <p className="form-text">
                  {t("subscriptionForm.descriptionPart1")} <br />
                  {t("subscriptionForm.descriptionPart2")}{" "}
                  <b>{t("subscriptionForm.descriptionPart3")}</b>
                  {t("subscriptionForm.descriptionPart4")}
                </p>
                <p className="mt-10p tell-link">
                  <button
                    type="button"
                    class="button button--naked tooltip-parent link-style-button"
                    disabled={!isEveryPayButtonEnabled}
                    onClick={this.onEverypayLinkClick}
                  >
                    <div class="button__wrapper">
                      <span class="button__wrapper-inner">
                        {t("subscriptionForm.bottomLinkText")}
                      </span>
                    </div>
                    {!isEveryPayButtonEnabled && (
                      <span className="tooltiptext">
                        {t("subscriptionBtnTooltip")}
                      </span>
                    )}
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(SubscriptionForm);
