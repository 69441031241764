import React from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import { getQueryParams } from "../utils/common";
import { redirectUrl } from "../configs";

import Header from "../components/header";
import Footer from "../components/footer";
import HeroSection from "../components/heroSection";
import KKKSection from "../components/kkkSection";
import PlansList from "../components/plansList";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currLanguage: "et",
      userInfo: null
    };
  }

  async componentDidMount() {
    const queryParams = await getQueryParams();
    if (queryParams === false) {
      window.location.assign(redirectUrl);
    } else {
      this.switchLanguage(queryParams.language);
      this.setState({
        currLanguage: queryParams.language,
        userInfo: queryParams
      });
    }
  }

  switchLanguage = lang => {
    this.setState({
      currLanguage: lang
    });

    this.props.i18n.changeLanguage(lang);
  };

  render() {
    return (
      <div>
        <Helmet>
          <script
            src="https://gotoand.dev/apollokino/public/inc/js/global.min.js"
            defer
          ></script>
        </Helmet>

        <Header
          onLangChange={this.switchLanguage}
          selectedLang={this.state.currLanguage}
        />
        <HeroSection />
        <PlansList
          userInfo={this.state.userInfo}
          currLanguage={this.state.currLanguage}
        />
        <KKKSection />
        <Footer />
      </div>
    );
  }
}

export default withTranslation()(LandingPage);
