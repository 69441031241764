import React from "react";
import ReactDOM from "react-dom";
import LandingPage from "./pages/landing";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import "./assets/css/global.min.css";
import "./style/index.scss";

window.gotoAndPlay = {
  svgPath: "/global.svg"
};

ReactDOM.render(<LandingPage />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
