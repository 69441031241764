import { tokenApiUrl, token } from "../configs";

export async function getTokenFromServer(productName, userId) {
  const data = {
    productName: productName,
    token: token,
    clientUserId: userId
  };

  const response = await fetch(tokenApiUrl, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });

  return await response.json();
}

export async function identifyClient(token) {
  try {
    let response = await window.zlick.identifyClient(token);

    if (response.error) {
      return {
        errorCode: response.error.statusCode
      };
    } else {
      return {
        userId: response.userId
      };
    }
  } catch (error) {
    console.error(error);
    if (error.statusCode) {
      return {
        errorCode: error.statusCode
      };
    }
  }
}

export async function sendPinCodeSMS(token, mobilePhoneNumber) {
  try {
    let idenClient = await identifyClient(token);

    if (idenClient.errorCode) {
      return idenClient;
    } else if (!idenClient.userId) {
      let response = await window.zlick.sendPinCodeSMS({
        token: token,
        mobilePhoneNumber
      });

      if (response.error) {
        return {
          errorCode: response.error.statusCode
        };
      } else {
        let challengeId = response.challengeId;
        return {
          challengeId
        };
      }
    } else if (idenClient.userId) {
      await subscribe(token, idenClient.userId);
      return {
        subscribed: true
      };
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
    if (error.statusCode) {
      return {
        errorCode: error.statusCode
      };
    }
  }
}

export async function verifyPinCode(token, challengeId, confirmationCode) {
  try {
    let authCompleteResponse = await window.zlick.verifyPinCode({
      token: token,
      confirmationCode,
      challengeId
    });

    if (authCompleteResponse.hasAccessRights === false) {
      return await subscribe(token, authCompleteResponse.userId);
    } else {
      return true;
    }
  } catch (error) {
    console.error(error);
    if (error.statusCode) {
      return {
        errorCode: error.statusCode
      };
    }
  }
}

export async function subscribe(token, userId) {
  try {
    let response = await window.zlick.subscribe({
      token: token,
      userId
    });

    return true;
  } catch (error) {
    console.error(error);
    return {
      errorCode: error.statusCode,
      zlickErrorCode: error.zlickErrorCode
    };
  }
}
