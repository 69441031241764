import React from "react";
import PropTypes from "prop-types";

import logoImg from "../assets/images/logo.svg";

const Header = props => (
  <div className="header js-header">
    <div className="header__top-bar">
      <div className="h-container header__top-bar-inner">
        <nav className="languages  header__languages">
          <ul className="languages__list">
            <li
              onClick={e => {
                e.preventDefault();
                props.onLangChange("et");
              }}
              className={
                props.selectedLang === "et"
                  ? "languages__item is-current"
                  : "languages__item"
              }
            >
              <span className="languages__link">est</span>
            </li>
            <li
              onClick={e => {
                e.preventDefault();
                props.onLangChange("ru");
              }}
              className={
                props.selectedLang === "ru"
                  ? "languages__item is-current"
                  : "languages__item"
              }
            >
              <span className="languages__link">rus</span>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div className="header__main">
      <div className="h-container">
        <div className="header__inner">
          <div className="logo  header__logo">
            <a href="/" className="logo__link">
              <img src={logoImg} alt="site-logo" className="logo__img" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Header;

Header.prototype = {
  selectedLang: PropTypes.string,
  onLangChange: PropTypes.func
};
